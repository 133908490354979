import ProjectItem from "./ProjectItem";
import moreImage from "../../assets/images/more.png";
import plopItImage from "../../assets/images/plopit.png";
import plopItIcon from "../../assets/icons/plopit.png";
function ProjectsSection() {
  return (
    <div className="projects-section container py-5">
      <h5 className="py-2">✨ My apps and games</h5>
      <div className="row row-cols-1 row-cols-xl-2 g-4 py-3">
        <div class="col align-items-stretch">
          <ProjectItem
            title="PlopIt! - A twist in solitaire games."
            description="get it on PlayStore"
            url="https://play.google.com/store/apps/details?id=com.JolouGames.FlipIt"
            icon={plopItIcon}
            image={plopItImage}
          />
        </div>
        <div class="col align-items-stretch">
          <ProjectItem
            title="More in the works..."
            description="stay tuned"
            image={moreImage}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsSection;
