import Icono from "../assets/images/profile.png";
function Header() {
  return (
    <div className="mb-5">
      <div>
        <header className="d-flex flex-wrap align-items-center pt-3 pb-1">
          <a
            href="/"
            className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
          >
            <img
              src={Icono}
              className="logo-icon rounded"
              alt="Jolou Games Logo"
            />
            <h6 className="mx-2 mt-3">JolouGames</h6>
          </a>
        </header>
      </div>
    </div>
  );
}

export default Header;
