import ProfileSection from "./components/ProfileSection";
import ProjectsSection from "./components/ProjectsSection";
import Footer from "../ui/Footer";

function HomeScreen() {
  return (
    <div className="home-screen container-fluid d-flex flex-column h-100">
      <div className="container flex-fill h-100">
        <div className="row row-cols-1 row-cols-md-2 g-4 align-items-center">
          <div className="col col-xl-4">
            <ProfileSection />
          </div>

          <div className="col col-xl-8">
            <ProjectsSection />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HomeScreen;
