function ProjectItem(props) {
  return (
    <div className="card d-flex h-100 p-2 rounded-lg">
      <div className="row g-3 h-100 card-body">
        <div className="col-5">
          <div className="">
            {props.icon && (
              <img
                src={props.icon}
                className="section-icon rounded object-fit-cover ratio ratio-1x1 mb-2"
                alt={props.title}
              />
            )}
            <h6 className="card-title text big">{props.title}</h6>
            <p className="card-text text-muted small">{props.description}</p>
          </div>
        </div>
        <div className="col-7 align-self-center">
          <a
            className=" align-self-center align-middle m-auto ratio ratio-16x9"
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={props.image}
              className="img-fluid rounded object-fit-cover"
              alt={props.title}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectItem;
