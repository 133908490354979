function Footer() {
  return (
    <div className="container-fluid mt-5">
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
          <p className="col-12 col-md-3 mb-0  py-2 text-muted text-center">
            Made with ❤️
          </p>
          <a
            class="col-12 col-md-3 nav-link px-md-4 py-2 text-muted text-center"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            class="col-12 col-md-3 nav-link px-md-4  py-2 text-muted text-center"
            href="https://play.google.com/store/apps/dev?id=7007707594549846815"
          >
            Google Play
          </a>
          <p className="col-12 col-md-3 mb-0 text-muted text-center">
            © 2023 Jolou Games
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
