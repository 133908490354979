import avatar from "../../assets/images/profile.png";
function ProfileSection() {
  return (
    <div className="profile-section container p-5">
      <ProfileAvatar />
      <h1>Raúl Sánchez</h1>
      <p className="lead">Hello 👋</p>
      <p>I’m building a portfolio of beautiful apps and games</p>
    </div>
  );
}

function ProfileAvatar() {
  return (
    <div className="col profile-avatar d-block mb-3">
      <img className="rounded-circle img-fluid" src={avatar} alt="Profile" />
    </div>
  );
}

export default ProfileSection;
